/* High Contrast Mode */
body.high-contrast {
  background-color: #000;
  color: #fff;
}

body.high-contrast img,
body.high-contrast video {
  filter: invert(100%);
}

/* Hide Images */
body.hide-images img {
  display: none;
}

/* Highlight Links */
body.underline-links a {
  text-decoration: underline;
}

/* Highlight Buttons */
body.highlight-buttons button {
  border: 2px solid red;
  background-color: yellow;
}

/* Dark Mode */
body.dark-mode {
  background-color: #121212;
  color: #e0e0e0;
}

/* Grayscale Mode */
body.grayscale img,
body.grayscale video,
body.grayscale .grayscale-element {
  filter: grayscale(100%);
}

/* Mobile Improvements */
/* @media (max-width: 600px) {
  .MuiDialog-root .MuiDialogActions-root {
    padding: 16px;
    gap: 8px;
  }

  .MuiDialog-root .MuiButton-root {
    min-width: 120px;
    padding: 12px 16px;
  }
} */
