/* Button to open the popover */
.reorder-button {
  background-color: #2196f3;
  color: white;
  border-radius: 8px;
  padding: 12px 24px;
  border: none;
  box-shadow: 0 2px 4px rgba(33, 150, 243, 0.3);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
  font-weight: 600;
  position: relative;
  overflow: hidden;
}

.reorder-button:hover {
  background-color: #1976d2;
  box-shadow: 0 4px 8px rgba(25, 118, 210, 0.4);
}

.reorder-button:active {
  box-shadow: 0 1px 2px rgba(25, 118, 210, 0.4);
}

/* Popover styles */
.category-popover-paper {
  width: 300px;
  max-height: 400px;
  display: flex;
  flex-direction: column;
  padding: 16px;
  background-color: #ffffff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.editable-input {
  border: 1px solid;
  padding: 4px;
  border-radius: 4px;
  outline: none;
}

/* Draggable category styles */
.draggable-category {
  padding: 12px;
  margin-bottom: 8px;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: move;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, box-shadow 0.2s ease;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: space-between; /* This will push the text to the left */
  direction: rtl; /* Change direction to LTR for text */
}

.draggable-category:hover {
  background-color: #f1f1f1;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.draggable-category.dragging {
  background-color: #e0e0e0;
  opacity: 0.8;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  -webkit-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

.drag-icon {
  margin-left: 8px; /* Changed from margin-right to margin-left */
  cursor: grab;
  order: 1; /* This will move the icon to the right side */
}

.category-text {
  flex-grow: 1;
  text-align: right; /* Align text to the left */
  display: flex;
  align-items: center;
}

/* Button container styles */
.button-container {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}

/* Button styles */
.cancel-button,
.save-button {
  min-width: 80px;
}

.cancel-button {
  margin-right: 8px;
}

/* Title styles */
.popover-title {
  text-align: center;
  margin-bottom: 16px;
  font-weight: bold;
}

/* Content styles */
.popover-content {
  flex: 1 1 auto;
  overflow-y: auto;
  margin-bottom: 16px;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior: contain;
}

/* Add to your CategoryPopup.css */


.drag-icon {
  cursor: grab;
  touch-action: none;
  -webkit-touch-callout: none;
}

.dragging {
  opacity: 0.8;
  cursor: grabbing;
}
